import { Entity, ImageModel } from '@pinup-teams/common';
import { Course, OnboardingStage, User } from '@pt/models';

export enum AchievementType {
  Colleagues = 'colleagues',
  Managers = 'managers',
  Education = 'education',
  Onboarding = 'onboarding',
  Unique = 'unique',
  Corporate = 'corporate', // doesn't exist yet, but will be added in the future
}

export enum AchievementStatus {
  Sent = 'sent',
  Received = 'received',
}

export interface DictAchievement extends Entity {
  name: string;
  image: ImageModel;
}

export interface Achievement extends DictAchievement {
  achievedCount?: number;
}

export interface RewardedBy {
  user: User | null;
  course: Pick<Course, 'name'> | null;
  onboardingStage: Pick<OnboardingStage, 'name'> | null;
  rewardedCount: number;
  rewardedFor: string;
}

export interface AchievementDialog extends Achievement {
  rewardedBy: RewardedBy[];
  description: string;
}

export interface AdminAchievement extends DictAchievement {
  createdAt: string;
  visible: boolean;
}

export interface AdminAchievementEditable extends DictAchievement {
  achievementType: AchievementType;
  visible: boolean;
  description: string;
}

export interface HistoryAchievement extends Entity {
  achievement: DictAchievement;
  status: AchievementStatus;
  createdAt: string;
  comment: string;
  fromUser: User | null;
  toUser: User | null;
  course: Pick<Course, 'name'> | null;
  unique: boolean;
}

export interface UniqueAchievementPayload {
  achievementId: number;
  userIds: number[];
  rewardedFor: string;
}

export type UniqueAchievementSelectItem = Omit<DictAchievement, 'image'>;
